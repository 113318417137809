import { storeToRefs } from 'pinia';

import { useCountryStore } from './country.store';

export const useCurrencyStore = defineStore('payment/currency', () => {
  /* -- Stores -- */
  /* Стор страны */
  const countryStore = useCountryStore();
  const { country } = storeToRefs(countryStore);

  /* -- Getters -- */
  /* Информация о валюте выбранной страны */
  const currency = computed(() => country.value?.currency ?? null);

  return {
    currency,
  };
});
