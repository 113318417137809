import type { TResult } from '~/features/payment/types/result/client.types';
import type { TPossibleNull } from '~/types/Shared.types';
import { usePromoStore } from '~/features/payment/store/promo.store';
import { useTransactionStore } from '~/features/payment/store/transaction.store';

export const useResultsStore = defineStore('payment/results', () => {
  const {
    $api: { payment: PaymentService },
  } = useNuxtApp();

  /* -- Const -- */
  /* Результат транзакции */
  const result = ref<TPossibleNull<TResult>>(null);
  const txStore = useTransactionStore();
  const { orderId } = storeToRefs(txStore);

  /* -- Methods -- */
  const getResult = async () => {
    const { instantPromo } = storeToRefs(usePromoStore());

    if (result.value && 'sum' in result.value && instantPromo.value) {
      return;
    }

    try {
      result.value = await PaymentService.getPayment(orderId.value ?? '');
    } catch {
      result.value = null;
    }
  };

  return {
    getResult,
    result,
  };
});
