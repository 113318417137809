import type { TCountriesItem } from '~/features/payment/types/countries/client.types';

export const useCountriesStore = defineStore('payment/countries', () => {
  const {
    $api: { payment: PaymentService },
  } = useNuxtApp();

  /* -- Const -- */
  /* Список стран (код + флаг) */
  const countries = ref<TCountriesItem[]>([]);

  const isLoaded = ref(false);

  /* -- Methods -- */
  /* Получаем список стран */
  const getCountries = async () => {
    try {
      countries.value = await PaymentService.getCountries();
    } catch {
    } finally {
      isLoaded.value = true;
    }
  };

  return {
    countries,
    isLoaded,
    getCountries,
  };
});
