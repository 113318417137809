import { storeToRefs } from 'pinia';

import { useCountryStore } from './country.store';

import type { TPossibleNull } from '~/types/Shared.types';
import { ECountryMethodType, type ICountryMethod } from '~/features/payment/types/country/client.types';

export const useMethodsStore = defineStore('payment/methods', () => {
  /* -- Stores -- */
  /* Стор страны */
  const countryStore = useCountryStore();
  const { country } = storeToRefs(countryStore);

  /* -- Const -- */
  /* Выбранный метод оплаты */
  const selectedMethod = ref<TPossibleNull<ICountryMethod>>(null);

  /* -- Getters -- */
  /* Методы оплаты выбранной страны */
  const methods = computed<ICountryMethod[]>(() => country.value?.methods ?? []);
  /* Определение типа метода ( для отрисовки страницы ) */
  const isClassicMethod = computed(
    () => !selectedMethod.value || selectedMethod.value.type === ECountryMethodType.COMMON,
  );

  /* -- Methods -- */
  /* Отмечаем выбранный метод оплаты */
  const selectMethod = (method: ICountryMethod) => {
    selectedMethod.value = method;
  };

  /* -- Watchers -- */
  /* Очищение выбранного метода оплаты при смене страны */
  watch(
    country,
    () => {
      selectedMethod.value = methods.value[0] ?? null;
    },
    {
      deep: true,
    },
  );

  return {
    isClassicMethod,
    methods,
    selectMethod,
    selectedMethod,
  };
});
