import { useCountriesStore } from './countries.store';

import type { TPossibleNull } from '~/types/Shared.types';
import type { ICountry } from '~/features/payment/types/country/client.types';
import type { ISelectFieldOption } from '~/features/payment/components/SelectField/SelectField.types';

export const useCountryStore = defineStore('payment/country', () => {
  const {
    $api: { payment: PaymentService },
  } = useNuxtApp();

  const countriesStore = useCountriesStore();
  const { countries } = storeToRefs(countriesStore);

  /* -- Const -- */
  /* Выбранная страна */
  const country = ref<TPossibleNull<ICountry>>(null);
  /* Объект выбранной страны из селекта */
  const selectedCountry = ref<TPossibleNull<ISelectFieldOption>>(null);

  const isDefault = ref(false);
  const isLoaded = ref(false);

  /* -- Methods -- */
  /* Получаем информацию о стране по коду */
  const getCountry = async (code: string) => {
    isLoaded.value = false;
    if (code === 'default') {
      isDefault.value = true;
    }

    try {
      country.value = await PaymentService.getCountry(code);
      const foundCountry = countries.value.find((c) => c.value === country.value?.country.iso);

      if (!selectedCountry.value && foundCountry) {
        selectedCountry.value = foundCountry;
      }
    } catch {
      country.value = null;
    } finally {
      isLoaded.value = true;
    }
  };

  /* -- Watchers -- */
  /* При изменении выбранной страны - подгружать с сервера */
  watch(selectedCountry, async (newCountry) => {
    if (isDefault.value) {
      isDefault.value = false;
      return;
    }

    await getCountry(newCountry?.value ?? '');
  });

  watch(
    countries,
    (newCountries) => {
      if (!country.value || !newCountries.length) return;
      const foundCountry = newCountries.find((c) => c.value === country.value?.country.iso);

      if (!foundCountry) return;
      selectedCountry.value = foundCountry;
    },
    {
      immediate: true,
    },
  );

  return {
    country,
    isLoaded,
    getCountry,
    selectedCountry,
  };
});
