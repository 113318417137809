import { storeToRefs } from 'pinia';

import { useMethodsStore } from './methods.store';
import {
  ECountryMethodField,
  type ICountryMethodField,
  type ICountryMethodFieldOption,
} from '~/features/payment/types/country/client.types';
import type { TPossibleNull } from '~/types/Shared.types';
import { useField } from '~/features/payment/composables/useField';

type TFieldsObject = Record<string, ReturnType<typeof useField>>;

export const useAdditionalFieldsStore = defineStore('payment/additional-fields', () => {
  const { t } = useI18n();
  /* -- Stores -- */
  /* Стор методов оплаты */
  const methodsStore = useMethodsStore();
  const { selectedMethod } = storeToRefs(methodsStore);

  /* -- Const -- */
  const selectedDocument = ref<TPossibleNull<ICountryMethodFieldOption>>(null);
  /* Поля для заполнения */
  const fields = ref<TFieldsObject>({});

  /* -- Getters -- */
  /* Есть ли поля */
  const hasFields = computed(() => {
    return selectedMethod.value?.fields.length;
  });
  /* Доп.поля для выбранного метода оплаты */
  const shownFields = computed(() => {
    const result: Record<string, ICountryMethodField> = {};
    if (!selectedMethod.value) return result;
    for (const field of selectedMethod.value.fields) {
      result[field.field] = { ...field, label: t(`PaymentPage.formLabels.${field.field}`) };
    }

    return result;
  });

  const fieldsValid = computed(() => {
    for (const field of Object.values(fields.value)) {
      if (field.error || (field.required && !field.value)) return false;
    }

    return true;
  });

  const getFieldsValue = () => {
    const result: Record<string, TPossibleNull<string>> = {};

    for (const field of Object.values(shownFields.value)) {
      const inputField = fields.value[field.field];
      result[field.field] = inputField.value;
    }

    return result;
  };

  watch(
    selectedDocument,
    (newDocument) => {
      if (!fields.value[ECountryMethodField.DOCUMENT_TYPE]) return;
      fields.value[ECountryMethodField.DOCUMENT_TYPE].value = newDocument?.value ?? null;
    },
    {
      deep: true,
    },
  );

  watch(
    selectedMethod,
    () => {
      selectedDocument.value = null;
      fields.value = {};

      for (const field of Object.values(shownFields.value)) {
        if (field.field === ECountryMethodField.DOCUMENT_NUMBER) {
          fields.value[ECountryMethodField.DOCUMENT_TYPE] = useField(t, field.required);
        }

        fields.value[field.field] = useField(t, field.required, field.validator);

        if (!field.default) continue;
        fields.value[field.field].value = field.default;
      }
    },
    {
      deep: true,
    },
  );

  return {
    fields,
    fieldsValid,
    getFieldsValue,
    hasFields,
    selectedDocument,
    shownFields,
  };
});
